.section--bigText {
  text-align: center;

  .section__head {
    padding-bottom: 0.25rem;
  }

  .section__text {
    font-size: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
