.imageText {
  margin-bottom: 1.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__img {
    height: auto;
    width: 100%;
    vertical-align: top;
    border: 0;
  }

  &__content {
    background-color: #fff;
    display: flex;
    flex: 1 0 300px;
    flex-direction: column;
    text-align: center;
    max-width: 640px;
    padding-top: 1rem;
  }

  &__text {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__list {
    list-style: none;
    text-align: center;
    padding-inline-start: 0;
    font-weight: 300;

    & li {
      margin-bottom: 8px;

      & strong {
        font-size: 1.2rem;
        display: block;
        margin-bottom: 20px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__subtitle {
    text-align: center;
  }
}

@media (min-width: 660px) and (max-width: 991px) {
  .imageText {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 991px) {
  .imageText__content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .section--imageText {
    &__wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media (min-width: 992px) {
  $imgWidthSmall: calc(100% - 300px - 10px);
  $imgWidthLarge: calc(100% - 50% - 10px);

  .imageText {
    display: flex;
    align-items: center;

    &.talentProgram {
      flex-direction: row-reverse;
      max-width: 620px;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
    }

    &__img {
      width: $imgWidthSmall;
      flex: 0 1 $imgWidthSmall;
      max-width: 640px;
      height: auto;
      border-radius: 15px;
    }

    &--split-half {
      .imageText__img {
        width: $imgWidthLarge;
        flex: 0 1 $imgWidthLarge;
      }
    }

    &:nth-of-type(even) {
      .imageText__img {
        order: 1;
      }

      .imageText__content {
        padding-right: 20px;
        padding-left: 0;
      }
    }

    &__content {
      flex-direction: column;
      padding-top: 0;
      padding-left: 20px;
      text-align: left;
    }
  }

  .talentProgram.imageText .imageText__content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .talentProgram.imageText .imageText__text {
    text-align: center;
  }
}
