.smallText {
  &__text {
    text-align: center;
    margin-top: 0;
    margin-bottom: 1.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 992px) {
  .section--smallText {
    .section__head {
      padding-bottom: 1.5rem;
    }
  }
}
