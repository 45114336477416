.success {
  margin-top: 50px;

  &__items {
    display: flex;
    justify-content: space-between;
  }

  &__number {
    font-weight: 600;
    color: #265da5;
    margin-bottom: 6px;
    font-size: 20px;
    margin-top: 0;
  }

  &__text {
    margin: 0;
  }

  &__head {
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 374px) {
  .success__text {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .success__text {
    font-size: 20px;
  }
}

@media screen and (min-width: 992px) {
  .success__item {
    margin-top: 10px;
  }
}
