.iconsText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  &__item {
    flex: 0 0 calc(50% - 20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 2rem;
  }

  &__icon {
    margin-bottom: 1.25rem;
  }
}

@media (max-width: 991px) {
  .iconsText__item--count-5 {
    &:last-of-type {
      margin-left: 25%;
    }
  }
}

@media (min-width: 992px) {
  .iconsText {
    &__item {
      margin-bottom: 0;

      &--count-3 {
        flex-basis: calc((100% / 3) - 20px);
      }
      &--count-4 {
        flex-basis: calc((100% / 4) - 20px);
      }
      &--count-5 {
        flex-basis: calc((100% / 5) - 20px);
      }
    }
  }
}

/* ie 11 hacks */
@media all and (-ms-high-contrast: none) {
  .iconsText__text {
    width: 100%;
  }
}

.talentProgram .iconsText__icon {
  max-width: 80px;
  height: auto;
}

.section--iconsText.talentProgram .section__wrapper {
  max-width: 990px;
}
