.section--footer {
  background-color: #3a3a3a;
  margin-bottom: 0;
  padding-top: 60px;
  padding-bottom: 30px;
}

.social {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social_link {
  margin-left: 1rem;
  margin-right: 1rem;
}

.social__icon {
  height: 22px;
}

.international {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.international__title {
  color: #fff;
  font-size: 20px;
}

.international_icon {
  height: 17px;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

@media screen and (min-width: 375px) {
  .international_icon {
    margin-left: 0.6rem;
    margin-right: 0.6rem;
  }
}

@media screen and (min-width: 414px) {
  .international_icon {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav__links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav_link {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 0.25rem;
}

.nav-us {
  max-width: 525px;
  margin-right: auto;
  margin-left: auto;
}

.nav-us__grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-right: -5px;
  margin-left: -5px;
}

.nav-us__col {
  box-sizing: border-box;
  margin-bottom: 15px;
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}

.nav-us__col:last-child {
  margin-bottom: 0;
}

.nav-us__link {
  font-weight: 300;
  font-size: 14px;
  line-height: 1.2;
  width: 100%;
  text-decoration: none;
  color: #fff;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .nav-us__link {
    font-size: 16px;
  }
}

.nav-us__link span {
  font-size: 0.7em;
}
