.trustwidgets {
  margin-top: 35px;
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.trustwidgets__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.trustwidgets__item {
  flex: 1 1 40%;
  max-width: 250px;
  margin: 20px;
}

.trustwidgets__link {
  display: block;
}

.trustwidgets__img {
  width: 100%;
}

@media (max-width: 991px) {
  .trustwidgets__item {
    max-width: 200px;
  }
}
