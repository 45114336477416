.awards {
  margin-top: 35px;
}

.awards__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto;
}

.awards__item {
  flex: 1 1 30%;
  max-width: 180px;
  margin: 25px;
}
