.contact {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  &__img {
    width: 65px;
    margin-right: 15px;
    border-radius: 8px;
  }

  &__details {
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-weight: bold;
  }

  &__email {
    color: #00549f;
  }
  a {
    color: inherit;
  }

  &__position {
    font-weight: 300;
  }
}

.section--contact {
  .section__content {
    display: flex;
    flex-direction: column;
  }

  .section__text {
    text-align: center;
  }

  .section__text {
    margin-top: 0;
    margin-bottom: 3rem;
    font-size: 18px;
  }
}

.talentProgram.section--contact {
  .section__content {
    flex-direction: column;
  }
  a {
    color: inherit;
  }
}

.talentProgram .contact {
  justify-content: center;
}

.talentProgram .contact__img {
  width: 85px;
  margin-right: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .contact {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0;

    &__item {
      margin-bottom: 0;
      justify-content: center;

      &--count-3 {
        flex-basis: calc((100% / 3) - 20px);
      }

      &--count-4 {
        flex-basis: calc((100% / 2) - 20px);
        margin-bottom: 1.5rem;
      }

      &--count-5 {
        flex-basis: calc((100% / 5) - 20px);
      }
    }
  }

  .section--contact {
    .section__head {
      padding-bottom: 1rem;
    }
  }
}
