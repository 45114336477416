.verticalList {
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    text-align: center;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__introText {
    margin-bottom: 2rem;
    text-align: center;
  }

  &__icon {
    flex-basis: 70px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    &--number {
      color: white;
      font-size: 32px;
    }
  }

  &__text {
    margin: 0;
    flex-basis: calc(100% - 90px);
  }

  &__cta {
    text-align: center;
    margin-top: 3rem;
  }

  a {
    color: inherit;
  }
}

@media (min-width: 992px) {
  .verticalList {
    &__introText {
      margin-top: -1rem;
    }

    &__item {
      flex-direction: row;
      text-align: left;
    }

    &__icon {
      margin-bottom: 0;
    }
  }
}

.section--blue.talentProgram {
  margin-bottom: 0;
}
