.trust {
  margin-top: 35px;
  padding-top: 25px;
  padding-bottom: 25px;
}

@media (max-width: 991px) {
  .trust__wrapper {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .trust {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
}
