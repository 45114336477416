.blocksCarousel {
  display: flex;
  margin-top: 40px;

  &__item {
    flex-basis: calc(100% / 3);
    margin-right: 10px;
    position: relative;
    background: #fff;

    &:last-child {
      margin-right: 0;
    }
  }

  &__inner {
    position: relative;
  }

  &__title {
    font-size: 20px;
    padding-bottom: 10px;
    font-weight: 100;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: #265da5;
    margin-bottom: 50px;
    text-align: center;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  &__text {
    width: 100%;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    color: #ffffff;
    padding-right: 12px;
    padding-left: 12px;
    padding-bottom: 8px;
    padding-top: 20px;
    line-height: 22px;
    position: absolute;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #000a1f 100%);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding-top: 30px;

    &:hover {
      cursor: default;
    }

    p {
      margin: 0;
    }
  }

  &__inner-title {
    font-weight: 600;
  }

  &__inner-subtitle {
    font-size: 12px;
    font-weight: 400;
  }

  &__inner-copy {
    font-style: italic;
    margin-bottom: 10px;
    max-height: 0;
    overflow: hidden;
    transition: all.7s ease-out;
    font-weight: 400;

    &:empty {
      padding: 0 !important;
    }
  }

  &__body {
    border-radius: 15px;
    display: flex;
    overflow: hidden;
  }

  &__img {
    width: 100%;
  }
}

.section__sub-title + .blocksCarousel {
  margin-top: calc(1.5rem - 10px);
}

.section--blocksCarousel {
  .section__text {
    text-align: center;
  }

  .carousel {
    .slide {
      background: #fff;
    }

    .control-dots {
      display: none;
    }
  }
}

@media (max-width: 991px) {
  .blocksCarousel {
    &__carousel {
      width: calc(100% + 30px);
      margin-left: -15px;
    }

    &__item {
      flex-basis: calc(100% / 3);
      margin-right: 10px;
      position: relative;
      background: #fff;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .section--development {
    .carousel {
      .control-arrow {
        top: 0;
        height: calc(100% - 80px);
        opacity: 1;

        &:hover {
          background: transparent;
        }

        &.control-next {
          right: 15px;
        }

        &.control-prev {
          left: 15px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .section__text--development {
    margin-top: 0;
    font-size: 20px;
  }

  .blocksCarousel {
    &__inner-copy--mobile {
      max-height: initial;
      padding: 15px 15px 0 15px;
      margin: 0;
    }

    &__title {
      margin-bottom: 30px;
    }
  }
}

@media (min-width: 992px) {
  .blocksCarousel {
    &__body {
      &:hover {
        .blocksCarousel__inner-copy {
          max-height: 300px;
          transition: all 0.5s ease-out;
          opacity: 1;
          cursor: default;
        }

        .blocksCarousel__info-icon {
          opacity: 0;
          transition: 0.2s ease-out;
        }
      }
    }

    &__info-icon {
      width: 15px;
      height: 15px;
      float: right;
      transition: 0.5s ease-in;
    }
  }
}
