.section--hero {
  background-position: center center;
  background-size: cover;
  margin-top: 70px;
  position: relative;
  overflow: hidden;
}

.section--hero-image {
  height: 232px;
}

.hero__title {
  font-weight: 400;
}

.section--hero::after {
  content: '';
  background-color: rgba(12, 44, 83, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
}

.section--hero-image::after {
  background-color: rgb(0 84 159 / 0.5);
}

.section__wrapper--hero {
  max-width: 100%;
  position: relative;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1;
  opacity: 0;
}

.section__wrapper--hero.playing {
  opacity: 1;
  transition: 1s ease-in;
}

.hero__content {
  color: #fff;
  padding: 30px;
  text-align: center;
  z-index: 1;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  bottom: 0;
}

.hero__text {
  margin-bottom: 15px;
}

@media (min-width: 992px) {
  .section--hero {
    margin-top: 83px;
  }
}

.hero__video-container {
  width: 100%;
  height: 100%;
}

.hero__video-container iframe {
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.talentProgram.section--hero-image {
  height: 400px;
  justify-content: center;
}

.talentProgram .hero__content {
  position: relative;
}
