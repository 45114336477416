.offices {
  display: flex;
  flex-wrap: wrap;
}

.offices__row {
  display: flex;
}

.offices__item {
  flex: 0 0 calc(100% / 3);
  width: calc(100% / 3);
  display: flex;
  position: relative;
}

.offices__item--text {
  background: #f2f2f2;
  justify-content: center;
  align-items: center;
  padding: 1.75rem;
  text-align: center;
  box-sizing: border-box;
}

.offices__name {
  margin-top: 0;
  margin-bottom: 0;
  transition: 0.7s ease-out;
}

.offices__body {
  margin-top: 0;
  margin-bottom: 0;
  max-height: 0;
  overflow: hidden;
  transition: 0.7s ease-out;
  opacity: 0;
  cursor: default;
}

.offices__overlay--image {
  position: absolute;
  height: 100%;
  width: 100%;
  transition: 0.3s ease-in;
}

.section__content--single {
  text-align: center;
  padding-top: 30px;
}

/* ie 11 hacks */
@media all and (-ms-high-contrast: none) {
  .offices__row {
    width: 100%;
  }

  .offices__img {
    width: 100%;
  }
}

@media (max-width: 659px) {
  .offices__item--text {
    padding: 0.75rem;
  }

  .offices__text {
    width: 100%;
  }

  .offices__name {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .offices__item--text {
    background: #fff;
  }
}

@media (max-width: 991px) {
  .offices {
    margin-bottom: -1rem;
  }

  .offices__row {
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .offices__item {
    flex-basis: calc((100% / 3) - 0.75rem);
    width: calc((100% / 3) - 0.75rem);
  }
}

@media (min-width: 992px) {
  .offices__row:hover .offices__body {
    max-height: 300px;
    transition: 0.3s ease-in;
    opacity: 1;
  }

  .offices__row:hover .offices__name {
    font-weight: 600;
    transition: 0.3s ease-in;
  }
}
