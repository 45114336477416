.audicarousel {
  display: flex;
  flex-wrap: wrap;

  &__introText {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  &__content {
    background-color: #fff;
    display: flex;
    flex: 1 0 300px;
    flex-direction: column;
    text-align: left;
    max-width: 640px;
    padding-top: 2rem;
  }

  &__slider {
    width: 100%;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  &__item {
    background: #fff;
  }

  &__subtitle {
    margin-bottom: 1rem;
  }

  &__img {
    height: auto;
  }
}

.carousel {
  .slide {
    background: #fff;
  }

  .control-arrow {
    background: transparent;
    opacity: 1;
  }

  .control-dots {
    max-width: calc(100% - 320px);
    padding-left: 0 !important;

    .dot {
      transition: 0.25s ease-in;
      border-radius: 0;
      box-shadow: none;
      width: 40px;
      height: 4px;
      opacity: 1;

      &.selected {
        background: #265da5;
      }
    }
  }
}

@media (max-width: 659px) {
  .carousel {
    .slide {
      &::after {
        content: '';
        position: absolute;
        width: calc(100% - 30px);
        left: 0;
        top: 0;
        height: 100%;
        border-left: 15px solid #fff;
        border-right: 15px solid #fff;
      }

      &.selected {
        &::after {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 660px) and (max-width: 991px) {
  .audicarousel__item {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 991px) {
  .section--carousel {
    .section__wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .carousel {
    .control-dots {
      display: none;
    }
  }

  .audicarousel {
    &__content {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 1.5rem;
      text-align: center;
    }
  }

  .carousel {
    .control-arrow {
      height: 70%;

      &.control-next {
        margin-right: 15px;
      }

      &.control-prev {
        margin-left: 15px;
      }
    }
  }
}

@media (min-width: 992px) {
  .section--carousel {
    .section__head {
      padding-bottom: 1.5rem;
    }
  }

  .audicarousel {
    &__item {
      display: flex;
      align-items: center;
    }

    &__img {
      width: calc(100% - 320px) !important;
      max-width: 640px;
      flex: 0 1 calc(100% - 320px);
      height: auto;
      border-radius: 15px;
    }

    &__content {
      flex-direction: column;
      padding-top: 0;
      padding-left: 20px;
    }

    &__text {
      padding-right: 15px;
    }
  }

  .carousel {
    .control-arrow.control-next {
      right: auto;
      left: 612px;
    }
  }
  .talentProgram .audicarousel__text {
    padding-right: 0;
  }
}

.talentProgram .audicarousel__subtitle {
  margin-bottom: 0;
  font-size: 20px;
}

.audicarousel__subtitle--small {
  font-weight: 600;
}

.talentProgram .audicarousel__text {
  font-style: italic;
}

.talentProgram .section__wrapper {
  max-width: 660px;
}
