.accordion {
  &__item {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__text {
    margin: 10px 0 25px;
  }

  a {
    color: inherit;
  }

  &__title {
    background: rgb(0, 84, 159);
    color: white;
    padding: 15px;
    border-radius: 10px;
    font-weight: 600;
  }

  summary {
    list-style: none;
  }
  summary::-webkit-details-marker {
    display: none;
  }
  summary::before {
    padding-right: 0.25rem;
    content: '+ ';
  }
  details[open] summary::before {
    padding-right: 0.25rem;
    content: '– ';
    font-weight: 800;
  }
  details[open] summary ~ * {
    animation: sweep 0.2s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px;
    }
    100% {
      opacity: 1;
      margin-top: 0px;
    }
  }
}

@media (min-width: 992px) {
  .accordion {
    &__title {
      background: rgb(0, 84, 159);
      color: white;
      padding: 15px;
      border-radius: 10px;
      font-weight: 600;
    }
  }
}
