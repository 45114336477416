.video__cont {
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.video__play {
  width: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.1s ease-in-out;
  z-index: 2;
}

.video__play:hover {
  width: 110px;
  cursor: pointer;
}

.video__overlay {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.video__video {
  width: 100%;
}

.video__video > div {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.video__frame-parent iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 599px) {
  .section--video .section__wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}
